<template>
  <div>
    <div style='margin: 20px 0'>
      <Input
        search
        class='txtinput'
        v-model='phone'
        placeholder='请输入手机号'
        style='width: 154px; margin-right: 20px'
      />
      <Input
        search
        class='txtinput'
        v-model='traceId'
        placeholder='请输入请求ID'
        style='width: 154px; margin-right: 20px'
      />
      <Input
        search
        class='txtinput'
        v-model='uri'
        placeholder='请输入uri'
        style='width: 154px; margin-right: 20px'
      />
      <Input
        search
        class='txtinput'
        v-model='uriName'
        placeholder='请输入uri名称'
        style='width: 154px; margin-right: 20px'
      />
      <DatePicker
        type='daterange'
        placeholder='时间区间'
        style='width: 250px; margin-right: 20px'
        v-model='Time'
      ></DatePicker>
      <Button type='primary' style='margin-left: 15px' @click='handleSearch'
        >查询</Button
      >
    </div>
    <Table :columns='columns' :data='data' class='table' border> </Table>
    <Page
      :total='total'
      :current='page'
      @on-change='handleCurrentChange'
      @on-page-size-change='handlePageSize'
      prev-text='上一页'
      next-text='下一页'
      ref='footer'
      show-sizer
      show-total
      style='float: right; background: #fff; margin: 30px 0'
    />
  </div>
</template>

<script>
let moment = require('moment')
moment().format()
export default {
  data() {
    return {
      phone: '',
      Time: [],
      columns: [
        {
          title: 'id',
          key: 'id',
          align: 'center',
        },
        {
          title: '创建时间',
          key: 'createTime',
          align: 'center',
        },
        {
          title: '手机号码',
          key: 'phone',
          align: 'center',
        },
        {
          title: '状态说明',
          key: 'rspMsg',
          align: 'center',
        },
        {
          title: '接口说明',
          key: 'uriName',
          align: 'center',
        },
      ],
      data: [],
      page: 1,
      pageCount: 10,
      total: 0,
      uriName: '',
      uri: '',
      traceId: '',
    }
  },
  created() {
    this.Time.push(moment().subtract(1, 'months').format('YYYY-MM-DD')) //一个月前的时间
    this.Time.push(moment().locale('zh-cn').format('YYYY-MM-DD')) //当前时间
    this.getTable(this.page, this.pageCount)
  },
  methods: {
    //查询
    handleSearch() {
      let etime = this.Time[1]
      let stime = this.Time[0]
      console.log(this.uriName)
      this.$http
        .post('/portal/v1/logReq/m/pageQueryLogs', {
          page: this.page,
          pageCount: this.pageCount,
          etime: etime || null,
          stime: stime || null,
          phone: this.phone || null,
          traceId: this.traceId || null,
          uri: this.uri || null,
          uriName: this.uriName || null
        })
        .then((res) => {
          console.log(res, 'table')
          if (res.code === 1) {
            this.data = res.data.records
            this.data.map((item) => {
              item.createTime = moment(item.createTime).format('YYYY-MM-DD')
              return item
            })
            this.total = res.data.total
          }
        })
    },
    //分页
    handleCurrentChange(val) {
      this.getTable(val, this.pageCount)
    },
    handlePageSize(val) {
      this.getTable(this.page, val)
    },
    //表格数据
    getTable(Page, pageCount) {
      this.$http
        .post('/portal/v1/logReq/m/pageQueryLogs', {
          page: Page,
          pageCount: pageCount,
        })
        .then((res) => {
          console.log(res, 'table')
          if (res.code === 1) {
            this.data = res.data.records
            this.data.map((item) => {
              item.createTime = moment(item.createTime).format('YYYY-MM-DD')
              return item
            })
            this.total = res.data.total
          }
        })
    },
  },
}
</script>

<style lang = 'less' scoped>
</style>
